import {
  FETCH_ALL_DATA_SUCCESS,
  FETCH_ALL_DATA_ERROR,
} from '../actions/actionTypes';

const initialState = {
  data: '',
  loading: true,
  error: false,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_DATA_SUCCESS:
      return { ...state, data: action.payload, error: false, loading: false };
    case FETCH_ALL_DATA_ERROR:
      return { ...state, data: action.payload, error: true, loading: false };
    default:
      return { ...state };
  }
};

export default dataReducer;
