import React from 'react';

//INPUT FIELD WITH LABEL ABOVE
export const InputFieldLabelAbove = ({ input, label, meta, required }) => {
  return (
    <div className="field">
      <label className="label ml-3">
        {required ? <span className="has-text-danger">* </span> : ''}
        {label}
      </label>
      <div className="control">
        <input
          {...input}
          id={input.id}
          type={input.type}
          className={`input ${meta.touched && meta.error ? 'is-danger' : ''}`}
        />
      </div>
    </div>
  );
};

//TEXTAREA WITH LABEL ABOVE
export const TextAreaLabelAbove = ({ input, label, meta, required }) => {
  return (
    <div className="field">
      <label className="label ml-3">
        {required ? <span className="has-text-danger">* </span> : ''}
        {label}
      </label>
      <div className="control">
        <textarea
          {...input}
          id={input.id}
          className={`textarea has-fixed-size ${
            meta.touched && meta.error ? 'is-danger' : ''
          }`}
          rows="3"
        />
      </div>
    </div>
  );
};

//DROPDOWN
export const Dropdown = ({}) => {
  return (
    <React.Fragment>
      <div className="dropdown is-active">
        <div className="dropdown-trigger">
          <button
            className="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            <span>Dropdown button</span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <a href="#" className="dropdown-item">
              Dropdown item
            </a>
            <a className="dropdown-item">Other dropdown item</a>
            <a href="#" className="dropdown-item is-active">
              Active dropdown item
            </a>
            <a href="#" className="dropdown-item">
              Other dropdown item
            </a>
            <hr className="dropdown-divider" />
            <a href="#" className="dropdown-item">
              With a divider
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
