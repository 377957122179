import React from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  composeValidators,
  requiredFieldValidation,
  validateEmail,
  mustBeString,
  mustBeNumber,
} from '../../validations/validations';
import {
  InputFieldLabelAbove,
  TextAreaLabelAbove,
} from '../reusable_components/FormComponents';
import { Error } from '../../errors/formValidationErrorField';
import styles from '../../style/css/Query.module.css';
import { sendQuery, removeItemFromQuery } from '../../actions';
import Modal from '../reusable_components/Modal';
import { Helmet } from 'react-helmet';

const QueryForm = () => {
  const dispatch = useDispatch();

  const queries = useSelector(state => state.query.queryText);
  const loading = useSelector(state => state.querySendLoading.loading);

  const onSubmit = formValues => {
    dispatch(sendQuery(formValues, queries));
  };

  const removeFromQuery = (event, index) => {
    event.preventDefault();
    dispatch(removeItemFromQuery(index));
  };

  const renderChoosenItems = () => {
    return (
      queries.length !== 0 && (
        <div>
          <label className="label ml-3">Izabrana oprema/usluge: </label>
          {queries.map((query, index) => (
            <div
              className={`${styles.box} box is-flex is-justify-content-space-between is-align-items-center py-3`}
              key={index}
            >
              <span>{query}</span>
              <button
                onClick={event => removeFromQuery(event, index)}
                className={`${styles.delete} delete is-medium is-danger`}
              />
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div className="container py-6">
      <Helmet>
        <title>Upit</title>
        <meta
          name="description"
          content="Upit nam možete poslati nakon što izaberete gradjevinsku opremu ili uslugu koja Vam je potrebna. Takođe, možete nas kontaktirati putem ove forme i ukoliko imate nekih dodantih pitanja. "
        />
        <link rel="canonical" href="https://www.gradjevinskaoprema.rs/query" />
      </Helmet>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="columns is-centered">
              <div className={`${styles.column} column is-half`}>
                {/* Name and last name input field */}
                <Field
                  name="name"
                  component={InputFieldLabelAbove}
                  type="text"
                  label="Ime i prezime"
                  required={true}
                  validate={composeValidators(
                    requiredFieldValidation,
                    mustBeString
                  )}
                />
                <Error name="name" />

                {/* Phone number input field*/}
                <Field
                  name="mobile_phone"
                  component={InputFieldLabelAbove}
                  type="text"
                  label="Broj mobilnog telefona."
                  required={true}
                  validate={composeValidators(
                    requiredFieldValidation,
                    mustBeNumber
                  )}
                />
                <Error name="mobile_phone" />

                {/* Email input field*/}
                <Field
                  name="email"
                  component={InputFieldLabelAbove}
                  type="text"
                  label="Email: "
                  required={true}
                  validate={composeValidators(
                    requiredFieldValidation,
                    validateEmail
                  )}
                />
                <Error name="email" />

                {renderChoosenItems()}

                {/* Textarea for query */}
                {queries.length !== 0 ? (
                  <Field
                    name="body"
                    component={TextAreaLabelAbove}
                    type="text"
                    label="Dodatni komentar/pitanje: "
                    required={false}
                  />
                ) : (
                  <Field
                    name="body"
                    component={TextAreaLabelAbove}
                    type="text"
                    label="Vaša poruka: "
                    required={true}
                    validate={requiredFieldValidation}
                  />
                )}

                <Error name="body" />

                <button
                  type="submit"
                  className={`button is-info is-outlined is-fullwidth ${
                    loading ? 'is-loading' : ''
                  }`}
                >
                  Pošalji upit
                </button>
                <p className="mt-3 is-size-7">
                  <i>
                    <span className="has-text-danger">*</span>Ovu formu možete
                    iskoristiti kako biste nas kontaktirali i postavili pitanja
                    ukoliko ih imate - bez odabira usluge ili opreme.
                  </i>
                </p>
                <p className="is-size-7">
                  <i>
                    <span className="has-text-danger">*</span>Zvezdicom su
                    obeležena obavezna polja.
                  </i>
                </p>
              </div>
            </div>
          </form>
        )}
      </Form>
      <Modal />
    </div>
  );
};

export default QueryForm;
