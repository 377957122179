import error404 from '../../assets/images/404.png';
const PageNotFound = () => {
  return (
    <div className="container">
      <div className="columns is-multiline">
        <div className="column is-6 is-offset-3">
          <figure className="image">
            <img src={error404} alt="404 - Page not found" />
          </figure>
        </div>
        <div className="column is-12">
          <p className="has-text-centered is-size-3">
            404 - Stranica nije pronađena
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
