import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../style/css/LandingPage.module.css';
import image from '../../assets/images/infra3.png';

const HeaderCarousel = ({ data }) => {
  return (
    <section className={`${styles.hero} hero is-medium`}>
      <div id={styles.header_carousel} className="hero-body">
        <div className={` ${styles.container_hc} container`}>
          <div className={`${styles.columns} columns is-vcentered py-3 px-3`}>
            <div
              className={`${styles.column} column is-full is-full-touch has-text-centered-touch`}
            >
              <div className={`${styles.header_content}`}>
                <div className={`${styles.header_inner_content}`}>
                    <p className="is-size-2 is-size-3-touch title has-text-white">
                      Potrebna Vam je grаđevinska oprema ili usluga?
                    </p>
                    <p
                      className={`is-size-4 ${styles.p_mar} sub has-text-white`}
                    >
                      Na pravom ste mestu!
                    </p>
                    <div className={`${styles.buttons} buttons `}>
                      <Link
                        to="/items/equipment"
                        className={`${styles.button} button is-info`}
                      >
                        Oprema
                      </Link>
                      <Link
                        to="/items/service"
                        className={`${styles.button} button is-info is-outlined`}
                      >
                        Usluge
                      </Link>
                  </div>
                </div>
              </div>
              {/* <Autocomplete suggestions={newList}/> */}
            </div>
            {/* <div className={`${styles.column} column is-half py-5 is-hidden-touch`}>
              <img src={image} />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderCarousel;
