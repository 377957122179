import Slider from 'react-slick';
import styles from '../../style/css/OurOffer.module.css';

const ItemSlider = ({ list }) => {
  //Settings for carousel
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    arrows: false,
    focusOnSelect: true,
    fade: true,
    // autoplay: true,
    // autoplaySpeed: 2500,
    // pauseOnHover: true,
  };

  return (
    <Slider {...settings} className={`${styles.slick_slider}`}>
      {list.map((image, index) => {
        return (
          // <div className="image is-square" key={index}>
          <div className="" key={index}>
            <img src={process.env.PUBLIC_URL + image.PATH} className={`${styles.img}`}/>
          </div>
        );
      })}
    </Slider>
  );
};

export default ItemSlider;
