import { Field } from 'react-final-form';

export const Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <p className="help is-danger mb-4 ml-2">{error}</p> : null
    }
  />
);
