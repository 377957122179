import api from '../api/api';

import {
  QUERY_SUCCESSFULLY_SENT,
  QUERY_FAILED_TO_SEND,
  ADD_ITEM_TO_QUERY_SUCCESS,
  REMOVE_ITEM_FROM_QUERY,
  OPEN_MODAL,
  CLOSE_MODAL,
  EMPTY_QUERY,
  FETCH_ALL_DATA_SUCCESS,
  FETCH_ALL_DATA_ERROR,
} from './actionTypes';

//Action creator for sending query to mail
export const sendQuery = (formValues, query) => async dispatch => {
  await api
    .post(
      '/api/mail',
      {
        ...formValues,
        query,
      },
      dispatch(setLoading('SET_LOADING_SEND_QUERY'))
    )
    .then(response => {
      dispatch(removeLoading('REMOVE_LOADING_SEND_QUERY'));
      if (response.data.status === 'success') {
        dispatch(
          openModal(QUERY_SUCCESSFULLY_SENT, 'Uspešno slanje upita!', [
            'Vaš upit je uspešno poslat. Kontaktiraćemo Vas u roku od 24h.',
            'Hvala na ukazanom poverenju!',
          ])
        );
        dispatch(emptyQuery());
      } else if (response.data.status === 'failed') {
        dispatch(
          openModal(QUERY_FAILED_TO_SEND, 'Neuspešno slanje upita!', [
            'Došlo je do greške prilikom slanja Vašeg upita.',
            'Molimo pokušajte kasnije ili nas kontaktirajte putem telefona.',
          ])
        );
      } else {
        dispatch(removeLoading('REMOVE_LOADING_SEND_QUERY'));
        dispatch(
          openModal(QUERY_FAILED_TO_SEND, 'Neuspešno slanje upita!', [
            'Došlo je do greške prilikom slanja Vašeg upita.',
            'Molimo pokušajte kasnije ili nas kontaktirajte putem telefona.',
          ])
        );
      }
    })
    .catch(error => {
      dispatch(removeLoading('REMOVE_LOADING_SEND_QUERY'));
      dispatch(
        openModal(QUERY_FAILED_TO_SEND, 'Neuspešno slanje upita!', [
          error.message,
          'Molimo Vas pokušajte kasnije ili nas kontaktirajte putem telefona.',
        ])
      );
    });
};

//Action creator for fetching all data from database
export const fetchAllData = () => async dispatch => {
  await api
    .get('/api/data')
    .then(response => {
      if (response.data.status == 'empty') {
        dispatch({
          type: FETCH_ALL_DATA_ERROR,
          payload: response.data.message,
        });
      } else {
        dispatch({ type: FETCH_ALL_DATA_SUCCESS, payload: response.data });
      }
    })
    .catch(error => {
      dispatch({ type: FETCH_ALL_DATA_ERROR, payload: error });
    });
};

//Action creator for adding specific item to query array
export const addItemToQuery = item => ({
  type: ADD_ITEM_TO_QUERY_SUCCESS,
  payload: item,
});

//Action creator for removig specific item from query array
export const removeItemFromQuery = index => ({
  type: REMOVE_ITEM_FROM_QUERY,
  payload: index,
});

export const emptyQuery = () => ({
  type: EMPTY_QUERY,
});

//MODAL

// Open modal
export const openModal = (modalType, title, text) => ({
  type: OPEN_MODAL,
  payload: {
    type: modalType,
    data: {
      title: title,
      text: text,
    },
  },
});

//Close modal
export const closeModal = () => ({
  type: CLOSE_MODAL,
});

//Set loading state and pass type which show which state should be affected
export const setLoading = type => ({
  type: type,
});

export const removeLoading = type => ({
  type: type,
});
