import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import PageNotFound from '../error_pages/PageNotFound';
import ItemSlider from './ItemSlider';
import styles from '../../style/css/Item.module.css';
import { REQUIRED_FIELD_ERROR } from '../../assets/strings/errorMessages';
import { addItemToQuery } from '../../actions';

const Item = ({ data, type, match }) => {
  const dispatch = useDispatch();
  //Set state for quantity
  const [spec, setSpec] = useState('');
  const [size, setSize] = useState('');
  const [specError, setSpecError] = useState('');
  const [sizeError, setSizeError] = useState('');
  const [status, setStatus] = useState(false);

  if (!data.error) {
    //get filtered list based on type
    const filteredList = filterList(data.data, type);
    //get specific item
    const item = getItemByTitle(filteredList, match.params.title);

    //Handle submit
    const addItemToQueryClick = () => {
      //Required field validation
      if (!spec && item.SIZES[0] != null && !size) {
        setSpecError(REQUIRED_FIELD_ERROR);
        setSizeError(REQUIRED_FIELD_ERROR);
      } else if (!spec) {
        setSpecError(REQUIRED_FIELD_ERROR);
        if (sizeError) {
          setSizeError('');
        }
      } else if (item.SIZES[0] != null && !size) {
        setSizeError(REQUIRED_FIELD_ERROR);
        if (specError) {
          setSpecError('');
        }
      } else {
        //Reset errors if there are any
        if (specError) {
          setSpecError('');
        }

        if (sizeError) {
          setSizeError('');
        }

        //Create query string
        let string = '';
        if (size) {
          string =
            'Naziv: ' +
            item.TITLE +
            ' - Specifikacija: ' +
            size +
            ' - Količina: ' +
            spec;
          //set status to true in order to paragraph with link to query show
          setStatus(true);
        } else {
          string = 'Naziv:' + item.TITLE + ' - Specifikacija: ' + spec;
        }

        dispatch(addItemToQuery(string));
      }
    };

    if (item) {
      return (
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content={item.DESCRIPTION}></meta>
            <title>{item.TITLE}</title>
            <link
              rel="canonical"
              href={`https://www.gradjevinskaoprema.rs/items/${item.TYPE_NAME}/${item.TITLE}`}
            />
          </Helmet>
          <div
            className={`columns is-multiline is-mobile ${styles.columns_sld}`}
          >
            {/* Image column */}
            <div
              className={`${styles.column_sld} column is-half-desktop is-half-tablet is-full-mobile`}
            >
              <ItemSlider list={item.IMAGES} />
            </div>
            {/* Text column */}
            <div className="column is-half-fullhd is-half-tablet is-full-mobile m_px0 has-text-centered-mobile m_mt">
              <div className="columns is-multiline">
                <div className="column is-full-fullhd  is-full-tablet is-full-mobile py-0 m_px0">
                  <div className="content">
                    <p className="is-size-3">
                      <b>{item.TITLE}</b>
                    </p>
                    <p>{item.DESCRIPTION}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Input field and button columm */}
            <div className="column is-offset-6-tablet m_px0">
              {/* Second column to align above column */}
              <div
                className={` ${status ? 'is-hidden' : ''} columns is-multiline`}
              >
                {/* DROPDOWN MENU */}
                {/* Hide elemet if there are not sizes for it  */}
                <div
                  className={` ${
                    item.SIZES[0] != null ? '' : 'is-hidden'
                  } column is-6-tablet is-12-mobile px-0`}
                >
                  <div className="control is-expanded pr-2">
                    <div
                      className={`${
                        sizeError ? 'is-danger' : ''
                      } select is-fullwidth`}
                    >
                      <select
                        className={` ${styles.dropdown}`}
                        onChange={e => {
                          e.preventDefault();
                          setSize(e.target.value);
                        }}
                        defaultValue="Dimenzije"
                      >
                        <option disabled className="has-text-grey">
                          Dimenzije
                        </option>
                        {item.SIZES.map((item, index) => {
                          return <option key={index}>{item}</option>;
                        })}
                      </select>
                      {/* show error if there is one */}
                      {sizeError ? (
                        <p className="help is-danger ml-2">{sizeError}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* This is shown IF THERE ARE NO sizes - dimnesions for selected item */}
                <div
                  className={` ${
                    item.SIZES[0] != null ? 'is-hidden' : ''
                  } column is-12 px-0`}
                >
                  <input
                    className={`input ${
                      specError ? 'is-danger is-outlined' : ''
                    }`}
                    placeholder="Specifikacija"
                    onChange={e => setSpec(e.target.value)}
                  />
                  {specError ? (
                    <p className="help is-danger ml-2">{specError}</p>
                  ) : null}
                  <p className="mt-2 is-size-7">
                    <span className="has-text-danger">* </span>U polje iznad
                    unesite specifikaciju koja Vam je potrebna. Na primer: "
                    {item.PLACEHOLDER}"
                  </p>
                </div>

                {/* This is shown IF THERE ARE sizes - dimnesions for selected item */}

                <div
                  className={` ${
                    item.SIZES[0] != null ? '' : 'is-hidden'
                  } column is-6-tablet is-12-mobile px-0`}
                >
                  <input
                    className={`input ${
                      specError ? 'is-danger is-outlined' : ''
                    }`}
                    placeholder="Količina"
                    onChange={e => setSpec(e.target.value)}
                  />
                  {/* show error if there is one */}
                  {specError ? (
                    <p className="help is-danger ml-2">{specError}</p>
                  ) : null}
                </div>

                <div className="column is-12 is-mobile-full px-0">
                  <button
                    className="button is-info is-fullwidth"
                    onClick={addItemToQueryClick}
                  >
                    Dodaj u upit
                  </button>
                </div>
              </div>
              {status ? (
                <div className="columns is-multiline has-text-centered">
                  <div className="column is-full px-0">
                    <hr className="mt-0"/>
                    <span className="is-size-4 has-text-weight-semibold has">
                      Uspešno ste dodali izabrani artikl u upit.
                    </span>
                  </div>
                  <div className="column is-full px-0">
                    <Link
                      to="/query"
                      className={`button  is-success mr-2 btn-m ${styles.btn_m}`}
                    >
                      Pregledaj upit
                    </Link>
                    <button
                      onClick={() => setStatus(false)}
                      className={`button is-info ml-2 ${styles.btn_m}`}
                    >
                      Nastavi sa pregledom opreme
                    </button>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <PageNotFound />;
    }
  } else {
    return (
      <div>
        <p>Problem while loading data..</p>
      </div>
    );
  }
};

//Filter list based on type
const filterList = (list, param) => {
  switch (param) {
    case 'Equipment':
      return list.filter(item => item.TYPE_NAME === 'equipment');
    case 'Service':
      return list.filter(item => item.TYPE_NAME === 'service');
    default:
      return list;
  }
};

//Get specific item by title
//This function goes through previously filtered list and
//and finds matched item, then assings its value
//to return value which is used to render page with
//choosen item data
const getItemByTitle = (list, param) => {
  let itm = '';
  list.map(item => {
    if (item.TITLE == param) {
      itm = item;
    }
  });
  return itm;
};

export default Item;
