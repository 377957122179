import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { CLOSE_MODAL } from "../../../actions/actionTypes";
import style from "../../../style/css/Modal.module.css";

const ModalSuccess = ({ title, text, status }) => {
  const dispatch = useDispatch();
  /*console.log(status);
   console.log(text[0]);
   console.log(text[1]); */

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className={`${style.modal_card} modal-card has-text-centered`}>
        <header
          className={`${style.modal_card_head} ${
            status ? style.succ : style.err
          } modal-card-head`}
        >
          <div className="column is-full">
            <p className="modal-card-title is-size-3 has-text-white has-text-centered">
              <b>{title}</b>
            </p>
          </div>
          <button
            className="delete"
            aria-label="close"
            onClick={() => dispatch({ type: CLOSE_MODAL })}
          />
        </header>
        <section className="modal-card-body has-text-black">
          <div className="content">
            <p className="is-size-5">{text[0]}</p>
            <b>
              <p className="is-size-6">{text[1]}</p>
            </b>
          </div>
        </section>

        <footer className={`${style.modal_card_foot} modal-card-foot`}>
          <button
            className={`${
              status ? style.btn_succ : "is-danger"
            } button has-text-white`}
            //need to explicitly close modal in order to hide it if user returns manually to register page
            onClick={() => dispatch({ type: CLOSE_MODAL })}
          >
            {status ? (
              <Link to="/items" className="has-text-white">
                Prikaži mi ponudu
              </Link>
            ) : (
              "U redu"
            )}
          </button>
          <button
            className={`${
              status ? style.btn_succ : "is-danger"
            } button is-outlined`}
            onClick={() => dispatch({ type: CLOSE_MODAL })}
          >
            <Link to="/">
              <span
                className={`${status ? "has-text-success" : "has-text-danger"}`}
              >
                Vrati me na početnu
              </span>
            </Link>
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ModalSuccess;
