import { removeLoading } from "../actions";
import { OPEN_MODAL, CLOSE_MODAL } from "../actions/actionTypes";

const initialState = {
  openedModal: "",
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, openedModal: action.payload };
    case CLOSE_MODAL:
      return { ...state, openedModal: "" };
    default:
      return state;
  }
};

export default modalReducer;
