import {
  ADD_ITEM_TO_QUERY_SUCCESS,
  REMOVE_ITEM_FROM_QUERY,
  EMPTY_QUERY,
} from '../actions/actionTypes';

const initialState = {
  queryText: [],
};

const queryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_QUERY_SUCCESS:
      return { ...state, queryText: [...state.queryText, action.payload] };
    case REMOVE_ITEM_FROM_QUERY:
      return {
        ...state,
        queryText: [
          ...state.queryText.slice(0, action.payload),
          ...state.queryText.slice(action.payload + 1),
        ],
      };
    case EMPTY_QUERY:
      return { ...state, queryText: [] };
    default:
      return state; //needed because of persisting state 
  }
};

export default queryReducer;
