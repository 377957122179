import React from 'react';
import MostWantedArticles from './MostWantedArticles';
import Steps from '../reusable_components/Steps';
import AboutUs from './AboutUs';
import Footer from '../reusable_components/Footer';
import FooterAction from './FooterAction';
import HeaderCarousel from './HeaderCarousel';
import { Helmet } from 'react-helmet';

const LandingPage = ({ data }) => {
  return (
    <div className="landing">
      <Helmet>
        <title>Početna</title>
        <meta
          name="description"
          content="GPS Invest d.o.o  je privredno društvo za izvođenje građevinskih radova u oblasti visokogradnje i niskogradnje kao i industrijskih i stambenih objekata. Pored ovoga kompanija se aktivno bavi i iznajmljivanjem građevinske opreme kao i pružanjem usluga u oblasti građevinarstva.
      Vaše je da poželite, a naše je da Vaše želje pretvorimo u realnost !"
        ></meta>
        <link rel="canonical" href="https://www.gradjevinskaoprema.rs" />
      </Helmet>
      <HeaderCarousel data={data} />
      <MostWantedArticles />
      <Steps />
      <AboutUs />
      <FooterAction />
    </div>
  );
};

export default LandingPage;
