import React from 'react';
import 'bulma-o-steps/bulma-steps.css';
import styles from '../../style/css/LandingPage.module.css';
const Steps = () => {
  return (
    <div className={`hero ${styles.hero}`}>
      <div className={`${styles.hero_body} hero-body`}>
        <div className="container">
          <div className={`${styles.title} title has-text-centered mb-6`}>
            U par koraka do rešenja Vašeg problema!
          </div>
          <div className={` ${styles.steps_container}  steps-container`}>
            <ul className={`${styles.steps} steps has-content-centered`}>
              <li className={`${styles.steps_segment} steps-segment`}>
                <span className={`${styles.steps_marker} steps-marker`}>1</span>
                <div className={` ${styles.steps_content} steps-content`}>
                  <p className="is-size-4">Pronađite</p>
                  <p className="sub">Pronađite ono što Vam je potrebno iz naše ponude.</p>
                </div>
              </li>
              <li className={`${styles.steps_segment} steps-segment`}>
                <span className={`${styles.steps_marker} steps-marker`}>2</span>
                <div className={`${styles.steps_content} steps-content`}>
                  <p className="is-size-4">Dodajte u upit</p>
                  <p className="sub">
                    Pronađenu opremu ili uslugu dodajte u upit klikom na dugme "Dodaj".
                  </p>
                </div>
              </li>
              <li className={`${styles.steps_segment} steps-segment`}>
                <span className={`${styles.steps_marker} steps-marker`}>3</span>
                <div className={`${styles.steps_content} steps-content`}>
                  <p className="is-size-4">Popunite formu</p>
                  <p className="sub">
                   Kada završite sa odabirom pristupite formi klikom na "Upit" u meniju i popunite datu formu.
                  </p>
                </div>
              </li>
              <li className={`${styles.steps_segment} steps-segment`}>
                <span className={`${styles.steps_marker} steps-marker`}>4</span>
                <div className={`${styles.steps_content} steps-content`}>
                  <p className="is-size-4">Pošaljite upit</p>
                  <p className="sub">
                   Kada popunite formu traženim podacima sve što je preostalo da uradite je da kliknete na dugme "Pošalji". To je to!
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
