import { useSelector } from 'react-redux';
import {
  QUERY_FAILED_TO_SEND,
  QUERY_SUCCESSFULLY_SENT,
} from '../../actions/actionTypes';
import ModalSuccess from './Modal/ModalSuccess';
import ModalFailed from './Modal/ModalFailed';

const renderModal = (type, data) => {
  switch (type) {
    case QUERY_SUCCESSFULLY_SENT:
      return <ModalSuccess title={data.title} text={data.text} status={true} />;
    case QUERY_FAILED_TO_SEND:
      return <ModalSuccess title={data.title} text={data.text} status={false} />;
    default:
      break;
  }
};

//Main
const Modal = () => {
  const openedModal = useSelector(state => state.modal.openedModal);
  if (!openedModal) {
    return null;
  }
  return <div>{renderModal(openedModal.type, openedModal.data)}</div>;
};

export default Modal;
