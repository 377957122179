import { Link } from 'react-router-dom';
import styles from '../../style/css/LandingPage.module.css';
const FooterAction = () => {
  return (
    <div className={`hero ${styles.footer_action}`}>
      <div className={`${styles.hero_body} hero-body`}>
        <div className={`${styles.container} container content is-flex is-justify-content-center`}>
          <div className="is-flex is-align-items-center">
            <span className="is-size-4">Želite da znate da li imamo ono što je Vama potrebno?</span>
            <Link to="/items" className="button ml-3 is-info">
              Istražite našu ponudu
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterAction;
