import React from 'react';

const Card = ({ image, text, title }) => {
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image is-4by3">
          <img src={process.env.PUBLIC_URL + image} alt="Image" />
        </figure>
      </div>
      <div className="card-content is-flex has-text-centered is-justify-content-center is-align-content-center">
        <div className="media my-3">
          <div className="media-content">
            <p className="is-size-4 has-text-weight-bold is-size-6-mobile">{title}</p>
          </div>
        </div>
        <div className="content">{text}</div>
      </div>
    </div>
  );
};

export default Card;

// <div className={`card-content ${text ? '' : "is-flex has-text-centered is-justify-content-center is-align-content-center"}`}>
//   <div className={`media  ${text ?  "" : 'my-3'}`}></div>
