import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../reusable_components/Card';
import styles from '../../style/css/LandingPage.module.css';
const MostWantedArticles = () => {
  return (
    <div className={`hero ${styles.mw_hero}`}>
      <div className={`${styles.hero_body} hero-body`}>
        <div className={`container ${styles.most_wanted}`}>
          <p className={`${styles.titlea} title`}>Najtraženije</p>
          <div className="columns is-multiline is-mobile is-justify-content-space-between">
            <div
              className={`column is-one-quarter-tablet is-half-mobile ${styles.zoom}`}
            >
              <Link to="/items/equipment/Podupirači" href="">
                <Card
                  image={
                    process.env.PUBLIC_URL + '/images/podupiraci.jpeg'
                  }
                  title="Podupirači"
                  text=""
                />
              </Link>
            </div>
            <div
              className={`column is-one-quarter-tablet is-half-mobile ${styles.zoom}`}
            >
              <Link to="/items/service/Zemljani radovi" href="">
                <Card
                  image={
                    process.env.PUBLIC_URL + '/images/zemljani_radovi3.JPG'
                  }
                  title="Zemljani radovi"
                  text=""
                />
              </Link>
            </div>
            <div
              className={`column is-one-quarter-tablet is-half-mobile ${styles.zoom}`}
            >
              <Link to="/items/equipment/H-nosači" href="">
                <Card                   image={process.env.PUBLIC_URL + "/images/h-nosaci.jpeg"}
 title="H-nosači" text="" />
              </Link>
            </div>
            <div
              className={`column is-one-quarter-tablet is-half-mobile ${styles.zoom}`}
            >
              <Link to="/items/equipment/Građevinski kontejneri" href="">
                <Card
                  image={process.env.PUBLIC_URL + '/images/kontejneri1.jpeg'}
                  title="Građevinski kontejneri"
                  text=""
                />
              </Link>
            </div>
          </div>
          <div className="is-flex is-justify-content-center mt-5">
            <Link
              to="/items"
              className="button is-info px-5 py-5 is-outlined mt-3"
            >
              Pogledaj sve
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostWantedArticles;
