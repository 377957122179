import contact from '../../assets/images/contact.png';
import styles from '../../style/css/Contact.module.css';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <div className="container">
      <div className="columns">
        <div className="column is-6 is-flex is-align-items-center">
          <div className="content">
            <Helmet>
              <title>Kontakt</title>
              <meta name="description" content="Kontakt stranica"></meta>
              <link
                rel="canonical"
                href="https://www.gradjevinskaoprema.rs/contact"
              />
            </Helmet>
            <p>
              Ukoliko želite da nas kontaktirate to možete učiniti putem
              telefona:
            </p>
            <ul>
              <li>026/4100-169 – Sedište firme</li>
              <li>063/84-81-349 – Direktor Goran Petrović</li>
            </ul>

            <p>
              Takođe, možete nas kontaktirati i putem mail-a:
              <b>gpsinvestoprema@gmail.com</b>
            </p>
            <p className="mb-4">
              Možete da nas posetite u našim prostorijama koje se nalaze na
              adresi Sinđelićeva 2, lokal broj 2, Smederevo.
            </p>
          </div>
        </div>
        <div className="column is-6">
          <img src={contact} />
        </div>
      </div>

      {/* <div className="column is-12"> */}

      {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2837.8888316284942!2d20.93001911582617!3d44.66062289472051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475093c0e55a153d%3A0x15929c67c5644936!2z0KHQuNC90ZLQtdC70LjRm9C10LLQsCAyLCDQodC80LXQtNC10YDQtdCy0L4!5e0!3m2!1ssr!2srs!4v1615814177553!5m2!1ssr!2srs"
            className="map"
            loading="lazy"
          /> */}
      {/* </div> */}
    </div>
  );
};

export default Contact;
