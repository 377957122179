import { combineReducers } from "redux";
import queryReducer from "./queryReducer";
import modalReducer from "./modalReducer";
import createLoadingReducerByName from "./loadingReducer";
import dataReducer from "./dataReducer";

const rootReducer = combineReducers({
  //STATE_NAME : REDUCER_NAME
  data: dataReducer,
  query: queryReducer,
  modal: modalReducer,
  querySendLoading: createLoadingReducerByName("SEND_QUERY"),
});

export default rootReducer;
