import {
  REQUIRED_FIELD_ERROR,
  MUST_BE_NUMBER_ERROR,
  ONLY_CHARACHTERS_ALLOWED,
  EMAIL_BAD_FORMAT_ERROR,
} from '../assets/strings/errorMessages';

//CHECKS IF FIELD IS EMPTY, RETURNS ERROR IF IT IS
export const requiredFieldValidation = value =>
  value ? undefined : REQUIRED_FIELD_ERROR;

//ONLY NUMBERS ALLOWED CHECK, RETURN ERROR IF IT IS NOT
export const mustBeNumber = value =>
  isNaN(value) ? MUST_BE_NUMBER_ERROR : undefined;

//ONLY CHARACHTERS ALLOWED CHECK
export const mustBeString = value =>
  /[^A-Za-z ]/.test(value) ? ONLY_CHARACHTERS_ALLOWED : undefined;

//COMPOSE VALIDATORS - apply multiple validators
export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

//CHECK EMAIL FORMAT
export const validateEmail = value =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  )
    ? undefined
    : EMAIL_BAD_FORMAT_ERROR;
