import React from 'react';
import { Link } from 'react-router-dom';
import mailicon from '../../assets/images/mail-icon1.png';
import locationicon from '../../assets/images/location-icon1.png';
import contacticon from '../../assets/images/contact-icon1.png';

const Footer = () => {
  return (
    <footer className="footer f">
      <div className="container">
        <div className="columns is-multiline has-text-centered ">
          <div className="column column_footer is-4">
            <figure className="image is-48x48">
              <img src={mailicon} />
            </figure>
            <p>gpsinvestoprema@gmail.com</p>
          </div>
          <div className="column column_footer is-4">
            <figure className="image is-48x48">
              <img src={locationicon} />
            </figure>
            <p>Sinđelićeva 2, lokal broj 2, Smederevo</p>
          </div>
          <div className="column column_footer is-4">
            <figure className="image is-48x48">
              <img src={contacticon} />
            </figure>
            <p>Tel: 026/4100-169</p>
            <p>Mob: 063/84-81-349</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
