import React, { useState } from 'react';
import logoImage from '../../assets/images/logo.png';
import styles from '../../style/css/LandingPage.module.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useClickOutside from '../../customHooks/useClickOutside';

const Navbar = () => {
  //State for controlling mobile menu toggle
  const [mobileMenu, setMobileMenu] = useState(false);
  const query = useSelector(state => state.query.queryText);
  //Toggle function
  const handleClick = e => {
    e.preventDefault();
    if (mobileMenu) {
      setMobileMenu(false);
    } else {
      setMobileMenu(true);
    }
  };

  let domNode = useClickOutside(() => {
    if (mobileMenu) {
      setMobileMenu(false);
    }
  });

  return (
    <nav
      ref={domNode}
      className="navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <img src={logoImage} alt="GPS Invest logo" id="logoImage" />
        </Link>

        {/* Burger menu for mobile */}

        <a
          role="button"
          className={`navbar-burger ${mobileMenu ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          href="#"
          onClick={handleClick}
        >
          <span aria-hidden="true"></span>
          {query.length !== 0 ? (
            <span aria-hidden="true" data-badge-nav={query.length}></span>
          ) : (
            <span aria-hidden="true"></span>
          )}
          <span aria-hidden="true"></span>
        </a>
      </div>

      {/* Right side of navbar */}
      <div
        id="navbarBasicExample"
        className={`navbar-menu ${mobileMenu ? 'is-active' : ''}`}
      >
        <div className="navbar-end">
          <div className="navbar-item">
            <Link to="/" className="navbar-item">
              Početna
            </Link>
            <Link to="/contact" className="navbar-item">
              Kontakt
            </Link>
            <Link to="/query" className="navbar-item">
              {query.length !== 0 ? (
                <span data-badge={query.length}>Upit</span>
              ) : (
                <span>Upit</span>
              )}
            </Link>

            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">Ponuda</a>

              <div className="navbar-dropdown">
                <Link to="/items/equipment" className="navbar-item">
                  Iznajmljivanje
                </Link>
                <Link to="/items/service" className="navbar-item">
                  Usluge
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
