import { BrowserRouter, Switch, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllData } from '../actions';
import LandingPage from './landing_page/LandingPage';
import QueryForm from './query_page/QueryForm';
import Footer from './reusable_components/Footer';
import Navbar from './reusable_components/Navbar';
import PageNotFound from './error_pages/PageNotFound';
import WholeOffer from './ourOffer/WholeOffer';
import Rent from './ourOffer/Rent';
import Services from './ourOffer/Services';
import RentItem from './ourOffer/RentItem';
import ServicesItem from './ourOffer/ServicesItem';
import history from '../history';
import '../style/css/global.css';
import Contact from './contact/Contact';
import ScrollToTop from './reusable_components/ScrollToTop';

const App = () => {
  //----------------------FETCH DATA ON LOAD----------------------
  const dispatch = useDispatch();
  //Get data from store
  const data = useSelector(state => state.data);
  //On first render
  useEffect(() => {
    //Fetch data on load if data is empty
    if (!data.data) {
      dispatch(fetchAllData());
    }
  }, []);

  //If data is loading show loader
  if (data.loading) {
    return <div className="loader" />;
  }

  //--------------------------------------------------------------

  return (
      <BrowserRouter history={history}>
        <ScrollToTop />
        <Navbar />
        <div className="wrapper">
          <Switch>
            <Route
              exact
              path="/"
              render={props => <LandingPage {...props} data={data} />}
            />
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/query">
              <QueryForm />
            </Route>
            {/* Whole offer */}
            <Route exact path="/items">
              <WholeOffer data={data} />
            </Route>
            {/* Route for list of items and specific item */}
            <Route exact path="/items/equipment">
              <Rent data={data} />
            </Route>
            <Route
              exact
              path="/items/equipment/:title"
              render={props => <RentItem {...props} data={data} />}
            />

            {/* Route for list of services and specific service */}
            <Route exact path="/items/service">
              <Services data={data} />
            </Route>
            <Route
              exact
              path="/items/service/:title"
              render={props => <ServicesItem {...props} data={data} />}
            />

            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
  );
};

export default App;
