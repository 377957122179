//GETTING DATA FROM DATABSE
export const FETCH_ALL_DATA_SUCCESS = "FETCH_ALL_DATA_SUCCESS";
export const FETCH_ALL_DATA_ERROR = "FETCH_ALL_DATA_ERROR";

//SENDING QUERY TO MAIL
export const QUERY_SUCCESSFULLY_SENT = "QUERY_SUCCESSFULLY_SENT";
export const QUERY_FAILED_TO_SEND = "QUERY_FAILED_TO_SEND";

//UPDATING QUERY
export const ADD_ITEM_TO_QUERY_SUCCESS = "ADD_ITEM_TO_QUERY_SUCCESS";
export const REMOVE_ITEM_FROM_QUERY = "REMOVE_ITEM_FROM_QUERY";
export const EMPTY_QUERY = "EMPTY_QUERY";

//MODAL
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

//LOADING
export const SET_LOADING = "SET_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";
