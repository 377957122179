import Card from '../reusable_components/Card';
import { Link } from 'react-router-dom';
import NeedSomethingElse from './NeedSomethingElse';
import { Helmet } from 'react-helmet';

const ListOfItems = ({ data, type, path }) => {
  if (!data.error) {
    //get filtered list based on type
    const filteredList = filterList(data.data, type);
    //If everything is OK then return (render)
    return (
      <div className="container py-6">
        <Helmet>
          <title>Gradjevinska oprema</title>
          <meta
            name="description"
            content={filteredList.map(item => {
              return item.TITLE;
            })}
          ></meta>
        </Helmet>
        <div className="columns is-multiline is-mobile">
          {filteredList.map(function (item, id) {
            return (
              <div
                className="column is-one-quarter-tablet is-half-mobile vertical-gap"
                key={id}
              >
                <Card title={item.TITLE} image={item.IMAGES[0].PATH} />
                <>
                  <Link
                    to={
                      path == 'items'
                        ? `items/${item.TYPE_NAME}/${item.TITLE}`
                        : `${item.TYPE_NAME}/${item.TITLE}`
                    }
                    className="button is-info is-outlined is-fullwidth mt-4"
                  >
                    Detaljnije
                  </Link>
                </>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p>Problem while loading data..</p>
      </div>
    );
  }
};

const filterList = (list, param) => {
  switch (param) {
    case 'Equipment':
      return list.filter(item => item.TYPE_NAME === 'equipment');
    case 'Service':
      return list.filter(item => item.TYPE_NAME === 'service');
    case 'wholeOffer':
    default:
      return list;
  }
};

export default ListOfItems;
