import React from 'react';
import image from '../../assets/images/about21.png';
import styles from '../../style/css/LandingPage.module.css';

const AboutUs = () => {
  return (
    <div className={`${styles.about_us} hero`}>
      <div className="hero-body">
        <div className={`${styles.container} container`}>
          <div className="columns  is-vcentered">
            <div className="column is-half">
              <p className={`${styles.titlea} title`}>Nekoliko reči o nama</p>
              <p className="sub">
                <a
                  href="https://gpsinvest.rs"
                  target="_blank"
                  className="has-text-info"
                >
                  GPS Invest d.o.o 
                </a>
                &nbsp; je privredno društvo za izvođenje građevinskih radova u oblasti
                visokogradnje i niskogradnje kao i industrijskih i stambenih
                objekata. Pored ovoga kompanija se aktivno bavi i
                iznajmljivanjem građevinske opreme kao i pružanjem usluga u
                oblasti građevinarstva.
                <br />
                <br />
                Vaše je da poželite, a naše je da Vaše želje pretvorimo u
                realnost !
              </p>
            </div>
            <div className="column is-half">
              <figure id="about-us-img" className={`${styles.image} image`}>
                <img src={image} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
