const initialState = {
  loading: false,
};

const createLoadingReducerByName = (name = '') => {
  return function loadingReducer(state = initialState, action) {
    switch (action.type) {
      case `SET_LOADING_${name}`:
        return { ...state, loading: true };
      case `REMOVE_LOADING_${name}`:
        return { ...state, loading: false };
      default:
        return {...state};
    }
  };
};

export default createLoadingReducerByName;
